.show-label {
    .react-dual-listbox-custom {
      margin-top: 30px;
      height: 250px;
      .rdl-control-label {
        clip: auto;
        font-weight: 600;
        top: 85px;
        padding: 6px;
        color: #333;
      }
    }
  }
  
  .custom {
    height: 250px;
  }  