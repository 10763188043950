/**  =====================
      Custom css start
==========================  **/
body {
    font-family: $theme-font-family;
    font-size: $theme-font-size;
    color: $theme-font-color;
    font-weight: 400;
    background: $theme-background;
    position: relative;
    line-height: 1.4;
}

* {
    &:focus {
        outline: none;
    }
}

a {
    &:hover {
        outline: none;
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $theme-heading-color;
    font-weight: 400;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 44px;
}

h3 {
    font-size: 26px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 14px;
}

p {
    font-size: 14px;
}

strong {
    font-weight: 400;
}

.img-radius {
    border-radius: 50%;
}

.card .btn {
    padding: 4px 10px !important;
}

.card .video-btn {
    padding: 6px 9px !important;
}

.page-header-title + .breadcrumb {
    background: transparent;
    padding: 0;

    > .breadcrumb-item {
        a {
            color: #888;
        }

        &:last-child a {
            color: #111;
            font-weight: 600;
        }
    }
}

.theme-bg {
    background: $theme-color;
}

.theme-bg2 {
    background: $theme-color2;
}
/* ==========  card css start  =========== */

.anim-rotate {
    animation: anim-rotate 1s linear infinite;
}
@keyframes anim-rotate {
    100% {
        transform: rotate(360deg);
    }
}

.anim-close-card {
    animation: anim-close-card 1.4s linear;
}
@keyframes anim-close-card {
    100% {
        opacity: 0.3;
        transform: scale3d(.3, .3, .3);
    }
}

p.text-muted {
    font-size: 13px;
}
#custom-dashboard-tabs .tab-content {
    background-color: inherit;
}

.dashboard {
    box-shadow: none;
    h5 {
        &:after {
            height: 35px !important
        }
    }

    table {
        font-size: 12px;
    }
    .card-header {
        padding-top: 15px !important;
        padding-bottom: 0px !important;
        border-bottom: 0px !important;
    }
}

.card {
    border-radius: 6px;
    box-shadow: 0px 2px 12px 2px rgba(226, 233, 238, 0.5), 0px -4px 0px -8px  rgba(226, 233, 238, 0.5);
    border: none;
    margin-bottom: 30px;
    transition: all 0.5s ease-in-out;

    .card-header {
        background-color: transparent;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 25px;
        position: relative;

        + .card-block,
        + .card-body {
        }

        h5 {
            margin-bottom: 0;
            color: #000;
            font-size: 17px;
            font-weight: 400;
            display: inline-block;
            margin-right: 10px;
            line-height: 1.1;
            position: relative;

            &:after {
                content: "";
                background-color: $primary-color;
                position: absolute;
                left: -25px;
                top: 0;
                width: 4px;
                height: 20px;
            }
        }

        h5-lineless {
            margin-bottom: 0;
            color: #000;
            font-size: 17px;
            font-weight: 400;
            display: inline-block;
            margin-right: 10px;
            line-height: 1.1;
            position: relative;
        }
        &.borderless {
            border-bottom: none;

            h5:after {
                display: none;
            }
        }

        .card-header-right {
            right: 10px;
            top: 10px;
            display: inline-block;
            float: right;
            padding: 0;
            position: absolute;
            @media only screen and (max-width: 575px) {
                display: none;
            }

            .dropdown-menu {
                margin-top: 0;

                li {
                    a {
                        font-size: 14px;
                        text-transform: capitalize;
                    }
                }
            }

            .btn.dropdown-toggle {
                border: none;
                background: transparent;
                box-shadow: none;
                color: #888;

                i {
                    margin-right: 0;
                }

                &:after {
                    display: none;
                }

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
        }
    }

    .card-footer {
        border-top: 1px solid #f1f1f1;
        background: transparent;
        padding: 25px;
    }

    .card-block,
    .card-body {
        padding: 20px;
    }

    &.card-load {
        position: relative;
        overflow: hidden;

        .card-loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            background-color: rgba(256, 256, 256,0.7);
            z-index: 999;

            i {
                margin: 0 auto;
                color: $primary-color;
                font-size: 24px;
                align-items: center;
                display: flex;
            }
        }
    }

    &.full-card {
        z-index: 99999;
        border-radius: 0;
    }
}
/* ==========  scrollbar End  =========== */

.scroll-y {
    z-index: 1027;
}
/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */

.round-indicator {
    font-size: 8px;
    border-radius: 50%;
    background-color: #eee;
    padding:7px 6px 6px 7px;
    margin-right: 4px;
    display: inline-block;
    width: 25px;
    margin-top: 4px;
    height: 25px;
}

.round-indicator.anchor {
    cursor: pointer;

    &:hover {
        background-color: #cecece;
    }
}

.round-indicator.red {
    &:hover {
        background-color: #b16363;
    }
}

.fast-modal {
    transition: opacity .25s cubic-bezier(0, 1, .4, 1), transform .4s cubic-bezier(.18, 1.25, .4, 1);
    transform: scale(0.8);
    opacity: 0;
    display: block !important;
}

.fast-modal.show {
    opacity: 1;
    transform: scale(1);
}

.fast-modal .modal-dialog {
    transition: none !important;
}

.custom-modal-content-overrides {
    border: none;
    box-shadow: 0px 0px 200px 0px rgba(0, 0, 0, 0.65);
}

.no-shadow {
    box-shadow: none;
}

// Dropdown Animations------------------------

.dropdown .dropdown-menu {
    display: block;
    opacity: 0;
    transform: scale(0.85);
}

.dropdown.show .dropdown-menu {
    opacity: 1;
    transform: scale(1) !important;
}

.dots-drpdwn .dropdown-menu, .btn-drpdwn .dropdown-menu, .custom-dropdown-button .dropdown-menu {
    inset: 36px 0px auto auto !important;
}

.btn-right-align-drpdwn .dropdown-menu {
    inset: 36px auto auto 0px !important;
}

.dots-drpdwn .btn {
    padding: 0px 10px 6px 10px !important;
}

.dots-drpdwn {
    margin-right: 0;
}

.dots-drpdwn .btn > i{
    margin-right: 0;
}

.header-drpdwn .dropdown-menu {
    inset: 60px -12px auto auto !important;
}

// -------------

.dropdown-menu {
    padding: 20px 0;
    margin-top: 15px;
    box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
    border: none;

    &.show {
        &:before {
            content: "\63";
            font-family: "pct";
            position: absolute;
            display: none;
            right: 15px;
            top: -5px;
            z-index: 1001;
            font-size: 40px;
            line-height: 0;
            color: #fff;
            text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
        }

        &.dropdown-menu-right {
            &:before {
                left: auto;
                right: 5px;
            }
        }
    }

    &[x-placement="top-start"] {
        margin-bottom: 15px;
        margin-top: 0;

        &.show {
            &:before {
                content: "\64";
                bottom: -5px;
                top: auto;
                text-shadow: 0 2px 2px rgba(69, 90, 100, 0.12);
            }
        }
    }

    > li {
        padding-left: 15px;
        padding-right: 15px;

        > a {
            padding: 5px;
            color: $theme-font-color;

            i {
                font-size: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
            background: transparentize($primary-color,0.9);

            > a {
                background: transparent;
            }
        }
    }
}
/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
    .container {
        max-width: 1540px;
    }
}
@media (max-width: 992px) {
    .container {
        max-width: 100%;
    }
}
/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/

.ruleset-col {
    padding: 0px;
    
    .tab-content {
        margin-left: -10px;
        padding: 10px;
    }
}

.recommendations-col {
    padding: 0px !important;
    .tab-content {
        padding: 0px;
        padding-top: 10px;
    }
}
/** Multi Dropdown */
/* bootstrap-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

 .inline-tab-container .card-header-pills {
    margin-left: 0;
    margin-right: 0;
 }

 .inline-anchor {
    cursor: pointer;
    // user-select: none;
 }
 .inline-edit-anchor {
    cursor: pointer;
    border-bottom: 1px dashed #007bff;
    user-select: none;
}

.text-c-trendgr {
    color: #14ac86;
}
.text-c-gr {
    color: #0dd1cc;
}

.text-c-orange {
    color: #f6914d;
}

.bootstrap-tree-select {

    .dropdown {
        width: 100%;
    }
    .dropdown-trigger {
        border-radius: 0.25rem !important;
        width: 100%;
        background-color: white;
    }
      
    .dropdown-trigger > span:after {
        font-size: 12px !important;
        color: #555 !important;
    }
      
    .toggle {
        font: normal normal normal 12px/1 FontAwesome !important;
        color: #555 !important;
        padding: 4px;
    }
      
    .toggle.collapsed::after {
        content: "\f067" !important;
    }
      
    .toggle.expanded::after {
        content: "\f068" !important;
    }

    .tag-item {
        .search {
            width: 100%;
        }
    }
    .tag-item .search {
        border-bottom: none;
        background: transparent;
    }

    .tag {
        background-color: #2683ff;
        border: none;
        border-radius: 3px;
        color: white !important;
        padding: 2px;
        word-break: break-all;
    }
    
    .tag-remove, .tag-remove:focus {
        color: white;
        outline: none;
    }


    .dropdown .dropdown-trigger.arrow.bottom:after {
        color: hsl(0,0%,80%);
        font-family: FontAwesome;
        content: "\f0d7";
        padding: 5px;
        float: right;
        border-left: 0px;
        padding-left: 10px;
        position: absolute;
        right: 2px;
        top: 5px;
    }

    .dropdown .dropdown-trigger.arrow.top:after {
        color: hsl(0,0%,80%);
        font-family: FontAwesome;
        padding: 5px;
        content: "\f0d8";
        float: right;
        border-left: 0px;
        padding-left: 10px;
        position: absolute;
        right: 2px;
        top: 5px;
    }
    
    .dropdown .dropdown-content {
        width: 100%;
        padding: 0px;
        height: 250px;
        overflow-y: scroll;
    }

    .node {
        padding: 8px;
        cursor: pointer;
        +.focused {
            background-color: #e3f2fd; 
        }
        &:hover {
            background-color: #e3f2fd;
        }
        label {
            margin-bottom: 0px;
            
        }
    }
    .root {
        padding: 0px !important;
        margin: 0px !important;
    }
}
/** done**/

.bd-example-row {
    background: $theme-background;
    padding: 15px;
    margin-bottom: 15px;

    .row {
        > [class^="col-"],
         > .col {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            background-color: rgba(86, 61, 124, .15);
            border: 1px solid rgba(86, 61, 124, .2);
        }
    }

    .row + .row {
        margin-top: 1rem;
    }

    .flex-items-bottom,
    .flex-items-middle,
    .flex-items-top {
        min-height: 6rem;
        background-color: rgba(255, 0, 0, .1);
    }
}

.bd-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(255, 0, 0, .1);
}

/* Docs examples */

.bd-example {
    position: relative;
    padding: 1rem;
    margin: 1rem;
    border: solid #ddd;
    border-width: 0.2rem 0 0;
    @media only screen and (max-height: 575px) {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: 0.2rem;
    }
}
/* Example modals */

.modal {
    z-index: 1072;
}

.popover, .tooltip {
    z-index: 1073;
}

.modal-backdrop {
    z-index: 1071;
}

.modal-lg {
    max-width: 1200px;
}

.modal-cxl {
    max-width: 1290px;
}

.bd-example-modal {
    background-color: #fafafa;

    .modal {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        left: auto;
        margin-right: auto;
        margin-left: auto;
    }
}
/* Example tabbable tabs */

.bd-example-tabs .nav-tabs {
    margin-bottom: 1rem;
}

.table-responsive {
    overflow: visible !important;
}
/* Code snippets */

.highlight {
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #f3f2fb;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @media only screen and (max-height: 575px) {
        padding: 1.5rem;
    }
}

.bd-content .highlight {
    margin-right: 15px;
    margin-left: 15px;
    @media only screen and (max-height: 575px) {
        margin-right: 0;
        margin-left: 0;
    }
}

.highlight {
    pre {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;
        border: 0;
    }

    pre code {
        font-size: inherit;
        color: #333;     }
}

/* =======================================================================
 ===============     Ui kit copy model style      ====================== */

/* Code examples */
.datta-example {
    position: relative;

    .datta-example-btns {
        position: absolute;
        right: 30px;
        top: -10px;
        opacity: 0;
        transform: rotateX(-90deg);
        transition: all 0.3s ease-in-out;

        .datta-example-btn {
            display: inline-block;
            line-height: 1;
            font-weight: 600;
            background: $primary-color;
            color: #fff;
            padding: 0.1875rem 0.3125rem;
            border-radius: 2px;
            white-space: nowrap;
            font-size: 11px;
            margin: 0 4px;

            &.copy::before {
                content: "COPY";
            }

            &.copied {
                background: $success-color !important;
                color: #fff !important;

                &::before {
                    content: "COPIED!";
                }
            }
        }
    }

    &:hover .datta-example-btns {
        top: -21px;
        transform: rotateX(0deg);
        opacity: 1;
    }
}
/* Modal */

.datta-example-modal {
    position: fixed;
    z-index: 1099;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #282c34;
    transform-origin: 50% 0;
    transform: scale(0.0);
    transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
    overflow: hidden !important;

    .datta-example-modal {
        transform: scale(1);
    }
}

.datta-example-modal-content {
    max-width: 100vw;
    margin: auto;
    padding: 50px;
    height: 100vh;
    overflow: auto;
    > pre {
        overflow: hidden;
        width: fit-content;

        > code {
            padding: 0;
            background: none;
            font-size: 16px;
        }
    }
}

.md-datta-example-modal-copy {
    display: block;
    position: fixed;
    top: 90px;
    right: 30px;
    margin-right: 8px;
    font-size: 1.5rem;
    border-radius: 50%;
    padding: 10px 15px;
    background: $primary-color;
    color: #fff;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    z-index: 1;
    white-space: nowrap;

    &:hover {
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
        color: #fff;
    }

    &.copied::before {
        content: "Copied to Clipboard Successfully ! . . .";
        position: absolute;
        display: block;
        right: 100%;
        margin-right: 10px;
        font-size: 14px;
        background: $success-color;
        line-height: 24px;
        height: 24px;
        border-radius: 3px;
        padding: 0 6px;
        top: 50%;
        margin-top: -12px;
    }
}

.datta-example-modal-close {
    display: block;
    position: fixed;
    top: 10px;
    right: 52px;
    color: #fff;
    opacity: 0.2;
    font-size: 3rem;
    font-weight: 100;
    transition: all 0.3s ease-in-out;
    z-index: 1;

    &:hover {
        color: #fff;
        opacity: 0.9;
    }
}
/* Code */
.cui-bottom-spacer {
    height: 12rem;
}
/* editor style for model */
.hljs {
    padding: 0.5em;
    color: #abb2bf;
    background: #282c34;
}

.hljs-comment,
.hljs-quote {
    color: #5c6370;
    font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
    color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
    color: #e06c75;
}

.hljs-literal {
    color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
    color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
    color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
    color: #61aeee;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}

.hljs-link {
    text-decoration: underline;
}
/* ================================    md animated mode start   ===================== */

.md-show.md-effect-12 ~ .md-overlay {
    background: $theme-color;
}
/* ================================    md animated mode End   ===================== */
/* ================================    range slider Start  ===================== */

.tooltip {
    &.in {
        opacity: 0.9;
    }

    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    &.top {
        padding: 5px 0;
        margin-top: -3px;

        .tooltip-arrow {
            bottom: 0;
            left: 50%;
            margin-left: -5px;
            border-width: 5px 5px 0;
            border-top-color: #000;
        }
    }
}
/* ================================    range slider End  ===================== */
/* ================================    owl-carousel slider Start  ===================== */

.owl-carousel {
    .owl-nav {
        button {
            &.owl-next,
            &.owl-prev {
                padding: 3px 9px;
            }
        }
    }

    button.owl-dot {}
}
/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */

.bootstrap-tagsinput {
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 2px;
    background: $form-bg;

    .tag {
        padding: 5px 12px;
        border-radius: 2px;
        line-height: 37px;
        margin-top: 5px;
        margin-right: 5px;

        [data-role="remove"] {
            margin-right: -5px;

            &:after {
                content: "\e8f6";
                padding: 0 2px;
                font-family: 'feather' !important;
            }
        }
    }
}
/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */

.ms-container {
    width: 100%;
}
/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */

.blockquote {
    border-left: 0.25rem solid $theme-border;
    padding: 0.5rem 1rem;

    &.text-right {
        border-left: none;
        border-right: 0.25rem solid $theme-border;
    }
}
/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */

.card .animated {
    animation-duration: 1s;
    border: 5px solid #fff;
    margin-bottom: 30px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}
/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */

.ie-warning {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #000000;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    padding: 50px 0;

    p {
        font-size: 17px;
    }

    h1 {
        color: #fff;
    }

    .iew-container {
        min-width: 1024px;
        width: 100%;
        height: 200px;
        background: #fff;
        margin: 50px 0;
    }

    .iew-download {
        list-style: none;
        padding: 30px 0;
        margin: 0 auto;
        width: 720px;

        > li {
            float: left;
            vertical-align: top;

            > a {
                display: block;
                color: #000;
                width: 140px;
                font-size: 15px;
                padding: 15px 0;

                > div {
                    margin-top: 10px;
                }

                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
}
/* ================================    browser  warning  End  ===================== */
/* material icon for material datetime picker */
/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url("https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
/* material icon for material datetime picker */
  

.bold-label {
    color: #1c1c1c !important;
}

.theme-bg3 {
    background: linear-gradient(-135deg, #3bc9ff 0%, #1aa7b9 100%)
}

.filter-box {
    padding: 10px;
}


// .rdt_TableBody {
//     overflow-y: visible !important;
// }

// .rdt_TableHeadRow {
//     .rdt_TableCol:nth-child(1) {
//         position: sticky;
//         left: 0;
//         width: 30px;
//         background-color: white;
//         z-index: 9;
//     }

//     .rdt_TableCol:nth-child(2) {
//         position: sticky;
//         left: 35px;
//         width: 20px;
//         background-color: white;
//         z-index: 9;
//     }

//     .rdt_TableCol:nth-child(3) {
//         position: sticky;
//         left: 50px;
//         background-color: white;
//         z-index: 9;
//     }
// }

// .rdt_TableRow {
//     .rdt_TableCell:nth-child(1) {
//         position: sticky;
//         left: 0;
//         width: 30px;
//         z-index: 2222;
//         background-color: white;
//     }

//     .rdt_TableCell:nth-child(2) {
//         position: sticky;
//         left: 35px;
//         width: 20px;
//         z-index: 2222;
//         background-color: white;
//     }

//     .rdt_TableCell:nth-child(3) {
//         position: sticky;
//         left: 50px;
//         border-right: 1px solid #ccc;
//         z-index: 2222;
//         background-color: white;
//     }
// }

/**
TreeSelect
**/

.tree-option .node-label::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  width: 35px;
  height: 15px;
  margin-right: 3px;
  background-color: black;
  font-size:8px;
  text-align: center;
  padding-top: 2px;
  border-radius: 10px;
}

.tree-option.enabled .node-label::before {
    background-color: #1aeab6;
}
.tree-option.paused .node-label::before {
    background-color: #e9a21d
}
.tree-option.archived .node-label::before {
    background-color: #f44236
}

.tree-option.sp-auto .node-label::before {
    content: 'SP A'
}
.tree-option.sp-manual .node-label::before {
    content: 'SP M'
}
.tree-option.sb .node-label::before {
    content: 'SB'
}
.tree-option.sd .node-label::before {
    content: 'SD M'
}

.option-card {
    // background-color: #fafafa;
    border: 2px dashed rgb(226, 226, 226);
    cursor: pointer;
    padding-top:10px;
    padding-bottom:10px;
    height: 230px;

    &:hover {
        background-color: #f0f0f0;
    }
}

.support-description {
    line-height: 2em;
    a {
        color: $primary-color !important;
    }
    ul {
        padding-left: 10px;
    }
}


input.form-control, textarea.form-control {
    background-color: white;

    &:hover, &:focus {
        background-color: white;
        box-shadow: none;
        outline: 0 none;
    }
}

.icon-help {
    margin-left: 5px;
    font-weight: normal;
    cursor: pointer;
    font-size: 11px;
    color: $primary-color;
}

.label {
    border-radius: 3px !important;
}

.tooltip-wrapper {
    max-width: 300px;
        // width: 300px;
    padding: 10px;
    overflow-wrap: break-word;
    line-height: 1.8em;
}

.rule-card {
    width: 85%;
    background-color: #f0f4f8;
    margin: 0 auto;
    border-radius: 5px;

    .nav-tabs, .tab-content, .nav-link.active {
        background-color: transparent;
    }
}

.info-bg {
    background-color: rgb(255, 244, 229);
    color: rgb(102, 60, 0);
    font-size: 12px;
}

.nav-tabs {
    margin-left: 0px;
}

.product-title {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.enable-ellipsis {
    /**
        Add the following style to element where this class is applied:
        -webkit-line-clamp: 4;
        max-width: $MAX_WIDTH_VALUE;
    **/
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.merged-column {
    display: flex;
    width: 100%;

    .row-style{
        text-overflow: ellipsis;
        width: 130;
        overflow: hidden; 
        white-space: nowrap;
    }
}

.wrapped-cell-text {
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    line-height: 1.3;
    margin-right: 2px;
}

// Bootstrap Overrides
.card {
    box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 5px -1px, rgba(0, 0, 0, 0.1) 0px 1px 3px -1px;
}

// Audit Styles
.summary-container{
    display: flex;
}

.content-sp-ar{
    justify-content: space-around;
}

.content-left{
    justify-content: flex-start;
}

.summary-col div{
    padding: 1px;
}

.summary-col-head div{
    font-size: 14px;
}

// DualColMultiSelect

.msl-item, .msl-sub-item {
    cursor: pointer;
    padding: 5px 2px;
    color: $primary-txt-color;
    border-bottom: 1px solid lightgray;
}

.msl-item:last-child , .msl-sub-item:last-child {
    border-bottom: none;
}

.msl-item {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.msl-item-disabled {
    color: grey !important;
    cursor: not-allowed;
    background-color: $disabled-bg !important;
}

.msl-sub-item {
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.msl-item:hover, .msl-sub-item:hover {
    background-color: $li-primary-bg-hov;
}

.msl-badge {
    padding: 1px 4px;
    margin: 0 4px;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 600
}

.msl-badge-primary {
    background-color: #12ad87;
    color: white;
}

.msl-badge-blue {
    background-color: #1cb4e6;
    color: white;
}

.msl-box {
    background-color: white;
    padding: 5px 8px;
    border: 1px solid #ced4da8a;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.msl-sel-box {
    font-weight: 500;
    background-color: $anchor-badge-bg;
    color: #007bff;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    padding: 2px 4px;
}

.msl-anchor-badge {
    font-size: 10px;
    padding: 3px 5px;
    background-color: $anchor-badge-bg;
    border-radius: 4px;
}

.msl-container {
    border: 1px solid lightgrey;
    border-radius: 6px;
}

.msl-function-bar {
    gap: 10px;
    background-color: rgb(240, 244, 248);
    padding-bottom: 6px;
}

// NAV Header
.custom-nav-header {
    position: sticky !important;
    background-color: white !important;
    box-shadow: rgba(50, 50, 93, 0.2) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px !important;
    height: 50px !important;
    min-height: unset !important;
    margin-left: 220px !important;
    width: calc(100% - 220px) !important;
}

.main-header {
    margin-left: 48px !important;
}

.main-header.left-nav-locked {
    margin-left: 245px !important;
    width: calc(100% - 245px) !important;
}

.custom-navbar{
    box-shadow: unset !important;
    width: 230px !important;
}

.custom-nav-header .navbar-nav > li {
    line-height: unset !important;
    padding: 0 16px 0 0;
}
  
.min-icon-dimensions {
    min-width: 16px !important;
    min-height: 16px !important;
}

.pcoded-header .dropdown.show:before {
    content: unset !important;
}

// Nav Logo
.pcoded-navbar .header-logo {
    width: 220px !important;
}

// Main content 
.custom-main-container {
    margin-left: 48px !important;
    min-height: 100% !important;
}

.custom-main-container.left-nav-locked {
    margin-left: 245px !important;
}

// Navbar org
.navbar-org {
    padding: 6px 20px 6px 0 !important;
    color: gray;
    max-width: 350px;
    text-overflow: ellipsis;
}

// Animate

.dropdown .show {
    transition: opacity 0.25s cubic-bezier(0, 1, 0.4, 1), transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1);
}

.smooth-transition {
    z-index: -10;
    opacity: 0;
    transform: scale(0.85);
    transition: opacity .25s cubic-bezier(0, 1, .4, 1), transform .4s cubic-bezier(.18, 1.25, .4, 1);
}

.smooth-transition-animate {
    opacity: 1;
    transform: scale(1);
    z-index: 1030;
}

// Custom Modal

$modal-lg-width : 1200px;
$modal-box-max-height: 86vh;
$modal-header-height: 34px;
$modal-footer-height: 40px;
$modal-body-max-height: calc(#{$modal-box-max-height} - #{$modal-header-height} - #{$modal-footer-height} - 16px);

.c-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(193, 201, 210, .7);
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-left: 110px;
    // margin-top: 20px;
}

.c-modal-container::-webkit-scrollbar {
  width: 10px;
}
.c-modal-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.c-modal-container::-webkit-scrollbar-thumb {
  background: #888; 
  cursor: pointer;
  border-radius: 10px
}
.c-modal-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.c-modal-box {
    background-color: white;
    box-shadow: 0 7px 14px 0 rgba(65, 92, 82, 0.08), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    ;
    border-radius: 8px;
    padding: 8px 12px;
    max-height: $modal-box-max-height;
}

.c-modal-box-lg {
    width: $modal-lg-width;
}

.c-modal-header {
    height: $modal-header-height;
    border-bottom: 1px solid #e4e4e4;
    padding: 4px 0px 8px 8px;
    color: #5b5b5b;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.c-modal-header .c-modal-header-close {
    padding-right: 2px;
    cursor: pointer;
}

.c-modal-header .c-modal-header-close i {
    font-weight: 600;
}

.c-modal-header .c-modal-header-close:hover {
    color: black;
}

.c-modal-body {
    overflow: auto;
    overflow-x: hidden;
    max-height: $modal-body-max-height;
    background-color: rgb(246, 248, 250);
}

.c-modal-footer {
    height: $modal-footer-height;
    padding-top: 6px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    border-top: 1px solid #e4e4e4;
}

// .dropdown {
//     .dropdown-menu {
//         transition: all 0.1s;
//         overflow: hidden;
//         // transform-origin: center center;
//         transform: scale(1, 0);
//         display: block;
//     }

//     &:hover {
//         .dropdown-menu {
//             transform: scale(1);
//         }
//     }
// }

.search-box{
    .search-btn {
        border-radius: 50%;
        box-shadow: none;

        &:active,
        &:focus,
        &:hover {
            outline: none;
            box-shadow: none;
        }

        padding: 2px 8px;
        margin-left: 5px;
        background: $primary-color;
        border-color: $primary-color;

        .input-group-text {
            color: #fff;
        }

        i.icon-search {
            font-size: 14px !important;
        }
    }

    .search-close {
        color: $header-dark-background;
        display: flex;
    }

    .input-group-text {
        background: transparent;
        border: none;
        border-radius: 0;
        padding: 0;
    }

    .input-group {
        background: #fff;
        border: 1px solid #cfcfcf;
        // box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
        padding: 5px 5px 5px 20px;
    }

    .form-control {
        transition: all 0.3s ease-in-out;
        font-size: 14px;
    }
}

.sticky-notification {
    text-align: center;
    padding: 10px;
    height: 40px;
    top: 52px;
    position: sticky;
    z-index: 1000;
    margin-left: -10px;
    font-size: 12px;
}

.offset-sticky {
    top: 40px !important;
}

.chart-custom-tooltip {
    padding: 20px;
    min-width: 150px;
    background-color: rgb(116, 136, 146);
    opacity: 0.99;
    border-radius: 20px;
    color: white;
    font-family: $theme-font-family !important;
}


// dayparting strategy editor schedule tab styles
.dayparting-schedule-tab-container .nav-pills {
    padding-left: 10px;
    border-bottom: 1px Solid #e1e1e1;
}

.dayparting-schedule-tab-container .tab-content {
    margin-left: -16px;
}

// Dayparting bottom bar
.dayparting-bottom-bar{
    position: fixed;
    left: 220px;
    bottom: 0;
    padding: 10px 30px 10px 10px;
    width: calc(100vw - 220px); 
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px -2px 4px 0px rgba(179,179,179,0.4);
}


// Rainbow gradient transition/animation
.rainbow-gradient-wrapper {
    border: none;
    background: linear-gradient(124deg, #66CC64, #F5D34E,#BC84A7,#9D56DD, #30AFFA, #3EC58E);
    background-size: 1800% 1800%;
    -webkit-animation: rainbow 4s ease infinite;
    -o-animation: rainbow 4s ease infinite;
    animation: rainbow 4s ease infinite;
}

// Zoom In-and-Out with 30deg rotation scope
.icon-zoom-effect {
    -webkit-animation: bounce-and-zoom 2s linear infinite;
    -o-animation: bounce-and-zoom 2s linear infinite;
    animation: bounce-and-zoom 2s linear infinite;
}

@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}

@keyframes bounce-and-zoom {
    0%{transform: scale(1, 1) rotate(0deg)}
    25%{transform: scale(1.1, 1.1) rotate(-15deg)}
    50%{transform: scale(1, 1) rotate(0deg)}
    75%{transform: scale(1.1, 1.1) rotate(15deg)}
    100%{transform: scale(1, 1) rotate(0deg)}
}

.sm-dropdown, .Recent-Users .header-dropdown-components {
    .select__control {
        min-height: 29.5px;
    }

    .select__control .select__indicators .select__dropdown-indicator {
        padding: 4px;
    }
}

.sm-dropdown, .Recent-Users .header-dropdown-components-left {
    .select__control {
        min-height: 29.5px;
        min-width: 250px;
    }

    .select__control .select__indicators .select__dropdown-indicator {
        padding: 4px;
    }
}

.custom-template {
    transition: border-color 0.3s;
    border-radius: 6px;
    padding: 6px;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid transparent;
    &.active {
        background-color: rgba(220, 220, 220, 0.3);
        border: 2px solid #87CEEB;
    }
    &:hover {
        border-color: #87CEEB;
    }
}

.template-card-description {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.template-card-title {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.custom-dropdown-button .dropdown-toggle {
    background-color: white;
    color: #748892;
    padding: 4px 10px;
    transition: background-color 0.3s;
}
  
.custom-dropdown-button .dropdown-toggle:hover {
    background-color: #748892;
    color: white;
}
  
.custom-date-range-popup {
    position: absolute;
    top: 50px;
    right:16px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: right;
    z-index: 100;
}

.custom_date_range_popup-top {
    position: absolute;
    bottom: 510px;
    right: 30%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: right;
    z-index: 100;
}

.custom-date-range-content {
    background: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    width: 820px;
    height: 505px;
    display: flex;
    flex-direction: column;
}
  
.custom-date-range-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}
  
.custom-dropdown-button-daterange:hover {
    color: #04a9f5 !important;
}

.border-separated-cells .border-separated-child{
    border-right: 1px solid #dbe4ed;
}

.border-separated-cells .border-separated-child:nth-child(6), .border-separated-cells .border-separated-child:nth-child(12){
    border-right: none;
}

.widget-container, .editable-widget-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.widget-container .lazyload-wrapper {
    width: 100%;
    height: 100%;
}

.draggable-pivot {
    transition: 0.3s;
    z-index: -1;
    opacity: 0;
    cursor: grab;
    padding: 5px 3px 5px 1px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.sortable-widget-container:hover .draggable-pivot {
    z-index: 2;
    opacity: 1;
}

.editable-widget-container > .card{
    width: 100%;
    height: 100%;
}

.editable-widget-container .tab-content {
    width: 100%;
    height: calc(100% - 34px);
}

.editable-widget-container .tab-pane{
    width: 100%;
    height: 100%;
}

.dashboard-widget-group {
    width: 100%;
    height: 100%;    
}

.dashboard-widget-group > .tab-content{
    width: 100%;
    height: calc(100% - 42px);
    overflow: auto;
}

.full-height-ex-head {
    height: calc(100% - 42px);
}

.new-otp-input::placeholder {
    color: #D0D5DD !important; /* Your desired color for placeholder */
    opacity: 1; /* Optional: to ensure the color applies fully */
  }