/**
    Colors and shades
 */

// Primary
$adbrew-primary: #455DDC;
$adbrew-primary-light: #E4E9FF;
$adbrew-gradient: linear-gradient(211deg, rgb(192, 199, 237) -28%, $adbrew-primary 100%);

// Danger
$adbrew-danger: #DF1B41;
$adbrew-danger-light: rgba(227, 172, 183, 0.149);

// Warning
$adbrew-warning: #FF9500;


// Aqua
$adbrew-aqua-light: #F4F9FD;

// Others
$adbrew-gray: #687385;
$adbrew-light-gray: #00000040;

/**
    Metrics
 */

// Borders
$border-radius: 6px;

// Box Shadows
$card-shadow: 0px 2px 12px 2px rgba(226, 233, 238, 0.5), 0px -4px 0px -8px rgba(226, 233, 238, 0.5);

/**
    Font
*/
$adb-font-weight-heavy: 500;
$adb-font-weight-xheavy: 700;

/**
    Classes
 */
.adb-primary {
    color: $adbrew-primary;
}

.adb-gradient {
    background: $adbrew-gradient;
}

.adb-bg-primary {
    background-color: $adbrew-primary-light;
}

.adb-danger {
    color: $adbrew-danger;
}

.adb-bg-danger {
    background-color: $adbrew-danger-light;
}

.adb-warning {
    color: $adbrew-warning;
}

.adb-table-head-bg {
    background-color: $adbrew-aqua-light;
}

.adb-rounded-md {
    border-radius: $border-radius;
}

.adb-shadow-sm {
    box-shadow: $card-shadow;
}

.adb-gray {
    color: $adbrew-gray;
}

.adb-muted {
    color: $adbrew-light-gray;
}

.adb-heavy {
    font-weight: $adb-font-weight-heavy;
}

.adb-x-heavy {
    font-weight: $adb-font-weight-xheavy;
}

.nav-pills .nav-link {
    font-weight: 500;
}