@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .mask-gradient-fade {
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  background-color: #F9FAFB !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.unread:hover a{
  color:#007bff;
}

.unread a{
  color:#888;
}

.theme-bg4 {
  background: linear-gradient(-135deg, #69d78c 0%, #1ab964 100%)
}

.theme-bg5 {
  background: linear-gradient(-135deg, #8764FF 0%, #5858E8 100%)
}

/* Apply custom styles to scrollbar for Windows */
.windows-os ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.windows-os ::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 10px;
}

.windows-os ::-webkit-scrollbar-track {
  background-color: #f3f4f6;
}

.windows-os ::-webkit-scrollbar-corner {
  background-color: #f3f4f6;
}

.windows-os::-webkit-scrollbar {
  width: 9px; 
}

.windows-os::-webkit-scrollbar-thumb {
  background-color: #c1c5ca !important;
  border-radius: 10px;
}

.windows-os::-webkit-scrollbar-track {
  background-color: #e6e7e9 !important;
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: inline;
  vertical-align: baseline;
}

.progress-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 
    2em -2em 0 0em, 3em 0 0 -1em, 
    2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
    3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 
    2em -2em 0 0, 3em 0 0 0.2em, 
    2em 2em 0 0, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
     -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
     -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
     -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
    3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
    3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.__react_component_tooltip {
  background-color: #101828 !important;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  opacity: 1 !important;
  font-size: 10px !important;
  padding: 8px 12px !important;
  border-radius: 8px !important;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.loading-logo {
  display: inline-block;
  width: 138px;
  height: 81px;
  position: relative;
}

.shimmer-effect {
  animation: shimmer 2s linear infinite;
  background: linear-gradient(
    to right,
    #f0f1f3 0%,
    #d3d7db 50%,
    #f0f1f3 100%
  );
  background-size: 300% 100%;
  mask: url(#logo-mask);
  -webkit-mask: url(#logo-mask);
}