@mixin alert-variant-pc($background, $border, $color) {
    color: $color;
    border-color: $border;

    hr {
        border-top-color: darken($border, 5%);
    }

    .alert-link {
        color: darken($color, 10%);
    }
}

.swal2-container {
    z-index: 1073;
}

.swal-footer {
    text-align: center;
}